<template>
  <div id="creatives">
    <div class="container mb-5">
      <div class="row">
        <div class="col-12 text-center mb-5">
          <p>Ne se compose que des meilleurs talents, rencontrez...</p>
          <div class="team-title">NOS CRÉATIFS</div>
        </div>

        <div class="col-12 mt-3 vignette">
          <div
            class="row mt-5 align-items-center container-inverse-child"
            v-for="(task, index) in creativesInfos"
            :key="task.titre + index"
            :data-slide-marque="index"
          >
            <div class="col-12 col-md-8">
              <div class="image-hover img-inner-shadow">
                <img class="ratio" :src="task.image" :alt="task.titre" />
                <div class="layer"></div>
              </div>
            </div>
            <div class="col-12 mt-5 mt-md-0 mb-5 col-md-4 text-center">
              <h3 v-html="task.titre">{{ task.titre }}</h3>
              <p class="mb-0 mb-md-4 pb-lg-4" v-html="task.pro">
                {{ task.pro }}
              </p>
              <button
                class="bt-hover-bk m-md-0 w-md-100 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5"
                v-on:click="lien(task.video, task.lien)"
              >
                {{ task.button }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white text-dark">
      <div class="container pt-5">
        <div class="row pt-5">
          <div class="col-12 text-center mb-5 pb-4">
            <p>Vous aimez ce que vous voyez ?</p>
            <div class="work-title">TRAVAILLONS ENSEMBLE</div>
            <router-link to="/contact"
              ><button class="bt-hover">CONTACTEZ-NOUS</button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Creatives",
  data() {
    return {
      creativesInfos: [
        {
          image: require("../assets/images/creatives/gregory-le-moigne.jpg"),
          titre: 'GREGORY<br class="d-none d-md-block"/> LE MOIGNE',
          pro: "Réalisateur",
          button: "BANDE DÉMO",
          video: "video",
          lien: "creatives/gregory-le-moigne",
        },
        {
          image: require("../assets/images/creatives/ybao-benedetti.jpg"),
          titre: 'YBAO<br class="d-none d-md-block"/> BENEDETTI',
          pro: "Réalisateur",
          button: "BANDE DÉMO",
          video: "video",
          lien: "creatives/ybao-benedetti",
        },
        {
          image: require("../assets/images/creatives/franck-allera.jpg"),
          titre: 'FRANCK<br class="d-none d-md-block"/> ALLERA',
          pro: "Réalisateur",
          button: "BANDE DÉMO",
          video: "video",
          lien: "creatives/franck-allera",
        },
        {
          image: require("../assets/images/creatives/christophe-luparini.jpg"),
          titre: 'CHRISTOPHE<br class="d-none d-md-block"/> LUPARINI',
          pro: "Réalisateur",
          button: "BANDE DÉMO",
          video: "video",
          lien: "creatives/christophe-luparini",
        },
        {
          image: require("../assets/images/creatives/cassandra-coldeboeuf.jpg"),
          titre: 'CASSANDRA<br class="d-none d-md-block"/> COLDEBOEUF',
          pro: "Photographe",
          button: "SLIDESHOW",
          video: "slideshow",
          lien: [
            require("../assets/images/creatives/cassandra-coldeboeuf/anna_ccoldeboeuf22.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/beautyeditorial_coldeboeuf.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/enola_coldeboeuf-30.jpg"),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/kalkidan_coldeboeuf-28.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/ksenia_coldeboeuf-21.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/marcia_coldeboeuf-33.jpg'),

            require("../assets/images/creatives/cassandra-coldeboeuf/160617_cassandracoldeboeuf48.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/28012017-cassandracoldeboeuf27.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/bump_coldeboeuf023.jpg"),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/bump_coldeboeuf064.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/bump_coldeboeuf080.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/gis10-05-19_coldeboeuf-59.jpg'),

            require("../assets/images/creatives/cassandra-coldeboeuf/emma_ccoldeboeuf-12.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/ines_ccoldeboeuf-14.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/lauralyne_ccoldeboeuf-13.jpg"),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/louise_ccoldeboeuf-6.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/milena_ccoldeboeuf-26.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/tatiana_ccoldeboeuf-21.jpg'),

            require("../assets/images/creatives/cassandra-coldeboeuf/mokko_ccoldeboeuf-1.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/mokko_ccoldeboeuf-13.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/mokko_ccoldeboeuf-19.jpg"),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/mokko_ccoldeboeuf-24.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/mokko_ccoldeboeuf-32.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/mokko_ccoldeboeuff-5.jpg'),

            require("../assets/images/creatives/cassandra-coldeboeuf/26_02_2019.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/1508.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/1564.jpg"),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/1629.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/2455-hdr.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/haussmann-prestige-28_03.jpg'),

            require("../assets/images/creatives/cassandra-coldeboeuf/emling_janvier_2020-1.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/emling_janvier_2020-3.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/emling_janvier_2021-16.jpg"),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/emling-fevrier-2020_coldeboeuf-24.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/emling-mars-2020_coldeboeuf-8.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/emling-mars-2020_coldeboeufF-27.jpg'),

            require("../assets/images/creatives/cassandra-coldeboeuf/ceinture_bleu-1.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/new-york_montmartre-3.jpg"),
            require("../assets/images/creatives/cassandra-coldeboeuf/packshot-chocolat.jpg"),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/packshot-fruits-rouges.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/trousse_1.jpg'),
            //                            require('../assets/images/creatives/cassandra-coldeboeuf/verticaleLaccessoire054.jpg'),
          ],
        },
        /*{
                        image: require('../assets/images/creatives/yoddha.jpg'),
                        titre: 'YODDHA',
                        pro: 'Photographe',
                        button:'SLIDESHOW',
                        video: 'slideshow',
                        lien : [
                            require('../assets/images/creatives/yoddha/yoddha-0.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-1.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-2.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-3.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-4.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-5.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-6.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-7.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-8.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-9.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-10.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-11.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-12.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-13.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-14.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-15.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-16.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-17.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-18.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-19.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-20.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-21.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-22.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-23.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-24.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-25.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-26.jpg'),
                            require('../assets/images/creatives/yoddha/yoddha-27.jpg'),
                        ],
                    },*/
      ],
    };
  },
  methods: {
    lien: function(event1, event2) {
      console.log("event1: " + event1);
      console.log("event2: " + event2);
      if (event1 == "slideshow") {
        this.$root.$children[0].linkSlideshow(event2);
      } else if (event1 !== "") {
        this.$root.$children[0].linkPlayer(event2);
      } else {
        window.open(event2, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#creatives {
  padding-top: 100px;
}

.team-title {
  font-size: 42px;
  font-weight: bold;
}

/* inverse div */
@media screen and (min-width: 768px) {
  .container-inverse-child:nth-child(2n + 2) {
    div:first-child {
      order: 2;
    }
    div:last-child {
      order: 1;
    }
  }
}

/* vignettes */
.vignette {
  .img-inner-shadow {
    position: relative;
    .layer {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      transition: all 0.6s ease-in-out;
    }
  }

  h3 {
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
  }

  i.playfairdisplay {
    font-size: 14px;
  }
}

/* button */
.bt-hover-bk {
  position: relative;
  background: none;
  padding: 25px 80px;
  display: inline-block;
  margin: 15px 30px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  transition: all 0.3s;

  &:hover {
    background: #fff;
    color: #131314;
  }
}

/* */
.work-title {
  font-size: 42px;
  font-weight: bold;
}

/* button */
.bt-hover {
  position: relative;
  background: none;
  padding: 25px 80px;
  display: inline-block;
  margin: 15px 30px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  border: 1px solid #131314;
  color: #131314;
  transition: all 0.3s;

  &:hover {
    background: #131314;
    color: white;
  }
}
</style>
